
import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import Multiselect from 'vue-multiselect';
import workspaceModule from '@/store/modules/workspaceModule';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
    ComponentDetails: () => getComponent('schedule/ComponentDetails'),
    AddSchedule: () => getComponent('schedule/AddSchedule'),
    AddField: () => getComponent('schedule/AddField'),
    Multiselect,
    },
})

export default class EditScheduleGroup extends Vue {
  addJobIsLoading = false

  addJobGroupSelected = ''

  templateDetailsFieldIndexExpanded = []

    addNewWorkspacePopupAddField = false

    templateDetails = [
    {
      type: 'Fields',
      values: [

      ],
    }, {
      type: 'Rules',
      values: [

      ],
    },
  ]

  get editGroupDetails() {
    return scheduleModule.editGroupDetails;
  }

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
    // @ts-ignore
    text: i.name,
    value: i.email,
    }));
  }

  get enabledWells() {
      return assetsModule.enabledWells;
  }

  async created() {
    this.showEditRowPopup(scheduleModule.editGroupDetails);
  }

  showEditRowPopup(row) {
    let group = null;
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
      // @ts-ignore
      if (row.title_row.selection_id === workspaceModule.activeTable.Groups[x].ID) {
        // @ts-ignore
        group = workspaceModule.activeTable.Groups[x];
      }
    }
    // @ts-ignore
    this.selectAddViewOption(group.Columns);
  }

  closeAddNewWorkspacePopupAddField() {
    this.addNewWorkspacePopupAddField = false;
  }

  applyNewWorkspacePopupAddField(fieldSelection, fieldNameSelection) {
  if (fieldSelection !== '' && fieldNameSelection !== '') {
    let localIcon = '';
    let localValue = '';
    let iconType = 'MATERIAL';
    if (fieldSelection === 'Tasq Date Range') {
      localIcon = 'date_range';
      localValue = 'DATE';
    } else if (fieldSelection === 'Well') {
      localValue = 'WELL';
      iconType = 'WELL';
    } else if (fieldSelection === 'User') {
      localIcon = 'person';
      localValue = 'USER';
    } else if (fieldSelection === 'Vendor') {
      localIcon = 'engineering';
      localValue = 'VENDOR';
    } else if (fieldSelection === 'Cost') {
      localIcon = 'attach_money';
      localValue = 'COST';
    } else if (fieldSelection === 'Tags') {
      localIcon = 'tag';
      localValue = 'TAGS';
    } else if (fieldSelection === 'Text') {
      localIcon = 'format_size';
      localValue = 'TEXT';
    } else if (fieldSelection === 'Date') {
      localIcon = 'event';
      localValue = 'DATE';
    } else if (fieldSelection === 'File') {
      localIcon = 'picture_as_pdf';
      localValue = 'FILE';
    } else if (fieldSelection === 'Checklist') {
      localIcon = 'checklist';
      localValue = 'CHECKLIST';
    } else if (fieldSelection === 'Procedure') {
      localIcon = 'task';
      localValue = 'PROCEDURE';
    } else if (fieldSelection === 'Email') {
      localIcon = 'email';
      localValue = 'EMAIL';
    } else if (fieldSelection === 'Phone Number') {
      localIcon = 'phone';
      localValue = 'PHONE_NUMBER';
    } else if (fieldSelection === 'Start Date') {
      localIcon = 'event';
      localValue = 'START_DATE';
    } else if (fieldSelection === 'Due Date') {
      localIcon = 'event';
      localValue = 'DUE_DATE';
    } else if (fieldSelection === 'Status') {
      localIcon = 'task_alt';
      localValue = 'STATUS';
    }

    this.templateDetails[0].values.push({
      // @ts-ignore
      title: fieldSelection,
      // @ts-ignore
      icon: localIcon,
      // @ts-ignore
      icon_type: iconType,
      // @ts-ignore
      column_type: localValue,
      // @ts-ignore
      custom_name: fieldNameSelection,
    });
    this.addNewWorkspacePopupAddField = false;
    if (!this.getTemplateDetailsFieldIndexExpanded(0)) {
      this.setTemplateDetailsFieldIndexExpandedIcon(0);
    }
  }
  }

  selectAddViewOption(columns) {
    const fieldValues: any[] = [];
    fieldValues.push({
      title: 'Add new field',
      icon_type: 'ADD',
      column_type: '',
    });
    for (let x = 0; x < columns.length; x++) {
      fieldValues.push({
        title: columns[x].ColumnName,
        icon: this.getColumnTypeIcon(columns[x].ColumnType),
        icon_type: columns[x].ColumnType === 'WELL' ? 'WELL' : columns[x].ColumnType === 'RIG' ? 'RIG' : 'MATERIAL',
        column_type: columns[x].ColumnType,
        custom_name: columns[x].ColumnName,
      });
    }
    this.templateDetails = [
      {
        type: 'Fields',
        // @ts-ignore
        values: fieldValues,
      },
    ];
  }

  getColumnTypeIcon(columnType) {
    if (columnType === 'WELL') {
      return 'task';
    } if (columnType === 'USER') {
      return 'person';
    } if (columnType === 'VENDOR') {
      return 'engineering';
    } if (columnType === 'CHECKLIST') {
      return 'checklist';
    } if (columnType === 'FILE') {
      return 'picture_as_pdf';
    } if (columnType === 'TESTERS') {
      return 'quiz';
    } if (columnType === 'TASQ_DATE_RANGE') {
      return 'date_range';
    } if (columnType === 'COST') {
      return 'attach_money';
    } if (columnType === 'TAGS') {
      return 'tag';
    } if (columnType === 'TEXT') {
      return 'format_size';
    } if (columnType === 'DATE') {
      return 'event';
    } if (columnType === 'START_DATE') {
      return 'event';
    } if (columnType === 'DUE_DATE') {
      return 'event';
    } if (columnType === 'PROCEDURE') {
      return 'task';
    } if (columnType === 'EMAIL') {
      return 'email';
    } if (columnType === 'PHONE_NUMBER') {
      return 'phone';
    } if (columnType === 'STATUS') {
      return 'task_alt';
    }
      return 'task';
  }

  async confirmEditRow() {
    // @ts-ignore
    this.addJobIsLoading = true;
    const columns: any = [];
    for (let r = 0; r < this.templateDetails[0].values.length; r++) {
      for (let f = 0; f < scheduleModule.columnMapping.length; f++) {
        // @ts-ignore
        if (this.templateDetails[0].values[r].column_type === scheduleModule.columnMapping[f].ColumnType) {
          columns.push({
            ...scheduleModule.columnMapping[f],
            // @ts-ignore
            CustomName: this.templateDetails[0].values[r].custom_name,
          });
          break;
        }
      }
    }
    await scheduleModule.updateGroupColumns({
      // @ts-ignore
      group_id: this.editGroupDetails.title_row.selection_id,
      columns: JSON.stringify(columns),
    });
    // @ts-ignore
    this.addJobIsLoading = false;

    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  removeField(index) {
    this.templateDetails[0].values.splice(index, 1);
  }

  setTemplateDetailsFieldIndexExpandedIcon(indexPassed) {
    // @ts-ignore
    if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
      // @ts-ignore
      const index = this.templateDetailsFieldIndexExpanded.indexOf(indexPassed);
      if (index > -1) {
        this.templateDetailsFieldIndexExpanded.splice(index, 1);
      }
    } else {
      // @ts-ignore
      this.templateDetailsFieldIndexExpanded.push(indexPassed);
    }
  }

  getTemplateDetailsFieldIndexExpanded(indexPassed) {
    // @ts-ignore
    if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
      return true;
    }
    return false;
  }

  getTemplateDetailsFieldIndexExpandedIcon(indexPassed) {
    // @ts-ignore
    if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
      return 'expand_more';
    }
    return 'chevron_right';
  }

  editGroupHideClicked() {
    this.$emit('close-edit-group-details-popup');
    scheduleModule.setEditGroupDetails(null);
  }
}

